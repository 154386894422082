// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-readings-js": () => import("./../../../src/pages/readings.js" /* webpackChunkName: "component---src-pages-readings-js" */),
  "component---src-templates-all-categories-index-js": () => import("./../../../src/templates/allCategoriesIndex.js" /* webpackChunkName: "component---src-templates-all-categories-index-js" */),
  "component---src-templates-blogpost-js": () => import("./../../../src/templates/blogpost.js" /* webpackChunkName: "component---src-templates-blogpost-js" */),
  "component---src-templates-single-category-index-js": () => import("./../../../src/templates/singleCategoryIndex.js" /* webpackChunkName: "component---src-templates-single-category-index-js" */)
}

